// #region Estilos generales
// Colores: https://www.figma.com/file/wexswlXotXppQZoBAkfqjG/SEGO---Sistema-Dise%C3%B1o_Primitives?node-id=0%3A1
// Fuentes: https://www.figma.com/design/RHB84kMeIiHOxw5VBQwzHZ/Estilos-2024?node-id=0-1
// #endregion

// #region NavbarHeight
$navbar-height: (
  default: 110px,
  tablet: 66px,
  mobile: 54px,
  logged: 36px,
);

$navbar-plataforma-erronea-height: (
  default: 64px,
  tablet: 62px,
  mobile: 50px,
);
// #endregion

// #region ResponsiveBreakpoints
$responsive-breakpoints: (
  sm: 575.98px,
  md: 767.98px,
  lg: 991.98px,
  xl: 1199.98px,
  xxl: 1399.98px
);
// #endregion

// #region FontFamily
$font-family: (
  primary: "Poppins",
  secondary: "Montserrat",
  third: "Open Sans",
);
// #endregion

// #region FontWeight
$font-weight: (
  bold: 700,
  semibold: 600,
  medium: 500,
  regular: 400,
  light: 300,
);
// #endregion

// #region HeaderPlatformBar
$platform-header: 6px;
// #endregion

// #region Primarys
$primary-900: #02182B;
$primary-800: #424B57;
$primary-500: #637182;
$primary-400: #8F9CAD;
$primary-300: #B8C5D6;
$primary-200: #D4DCE5;
$primary-100: #F0F2F4;
$primary-75: #F9FAFB;
$primary-50: #FCFCFD;
// #endregion

// #region neutrals
$neutral-1000: #000000;
$neutral-900: #333333;
$neutral-800: #575757;
$neutral-500: #797979;
$neutral-400: #9b9b9b;
$neutral-300: #bdbdbd;
$neutral-100: #dfdfdf;
$neutral-0: #ffffff;
// #endregion

// #region Secondarys
$secondary-venture-800: #824113;
$secondary-venture-700: #a35118;
$secondary-venture-600: #cc651e;
$secondary-venture-500: #ff7e26;
$secondary-venture-400: #ffb27d;
$secondary-venture-300: #ffd1b1;
$secondary-venture-200: #ffeadb;
$secondary-venture-100: #fff9f5;


$secondary-funds-800: #12467D;
$secondary-funds-700: #1964B5;
$secondary-funds-600: #3E7DC3;
$secondary-funds-500: #6296D1;
$secondary-funds-400: #87AFDE;
$secondary-funds-300: #ABC8EC;
$secondary-funds-200: #D0E1FA;
$secondary-funds-100: #F2F6FC;

$secondary-factoring-800: #56426b;
$secondary-factoring-700: #6b5286;
$secondary-factoring-600: #8666a8;
$secondary-factoring-500: #a77fd2;
$secondary-factoring-400: #c3a8e1;
$secondary-factoring-300: #dacaed;
$secondary-factoring-200: #ece3f5;
$secondary-factoring-100: #f8f4fb;

$secondary-estate-500: #f9c339;
$secondary-estate-400: #fbd575;
$secondary-estate-300: #fce19c;
$secondary-estate-200: #fde9b5;
$secondary-estate-100: #fef3d7;
$secondary-estate-50: #fefbf1;

$secondary-creative-800: #5C1731;
$secondary-creative-700: #781E40;
$secondary-creative-600: #9B2652;
$secondary-creative-500: #DB3674;
$secondary-creative-400: #EEA3BF;
$secondary-creative-300: #F4C1D4;
$secondary-creative-200: #FBEBF1;
$secondary-creative-100: #FEF9FB;
// #endregion

// #region Semantic
$semantic-success-900: #255822;
$semantic-success-800: #2e6e2b;
$semantic-success-500: #3a8a36;
$semantic-success-400: #48ad43;
$semantic-success-300: #89d086;
$semantic-success-100: #d1eccf;
$semantic-success-50: #f4fbf4;

$semantic-info-900: #1a5868;
$semantic-info-800: #216e82;
$semantic-info-500: #2989a3;
$semantic-info-400: #33abcc;
$semantic-info-300: #78C8DE;
$semantic-info-100: #C2E6F0;
$semantic-info-50: #F3FAFC;

$semantic-warning-900: #956504;
$semantic-warning-800: #b87c05;
$semantic-warning-500: #e19805;
$semantic-warning-400: #f9a806;
$semantic-warning-300: #fbc55b;
$semantic-warning-100: #fde5b4;
$semantic-warning-50: #fffaf0;

$semantic-error-900: #8F0A0A;
$semantic-error-700: #B10A0A;
$semantic-error-500: #D10A0A;
$semantic-error-400: #F10A0A;
$semantic-error-200: #F66363;
$semantic-error-100: #FDD6D6;
$semantic-error-50: #FDF1F4;
// #endregion